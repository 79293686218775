import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  Popover,
  TextField,
  Box,
  Typography,
  Fab,
  IconButton,
  List,
  ListItem,
  Paper,
  Chip,
  Skeleton,
  Avatar,
  InputAdornment,
} from '@mui/material';
import { Dropdown, RedButton } from '../../components/CommonComponent';

// Icons
import {
  FaComments,
  FaPaperPlane,
  FaTimes,
  FaCog,
  FaExpandArrowsAlt,
  FaCompressArrowsAlt,
} from 'react-icons/fa';
import { GrPowerReset } from 'react-icons/gr';

// SCSS
import './product-chatbot.scss';

// Helpers
const enums = require('../../enums');
const helper = require('../../utils/helper');

// Constants
const API_KEY = process.env.REACT_APP_CHATBOT_API_KEY;
const dcMapping = {
  DC1: 'Taftville',
  DC2: 'Aberdeen',
  DC3: 'Shorewood',
  DC4: 'Carteret',
  DC5: 'San Bernardino',
};
const zoneList = [
  'Zone 1',
  'Zone 2',
  'Zone 3',
  'Zone 4',
  'Zone 5',
  'Zone 6',
  'Zone 7',
  'Zone 8',
  'Zone 9',
  'Zone 10',
];

// Utility function to escape HTML special characters
const escapeHTML = (str) =>
  str.replace(
    /[&<>'"]/g,
    (tag) =>
      ({
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        "'": '&#39;',
        '"': '&quot;',
      })[tag] || tag,
  );

// Utility function to validate URLs
const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

// Function to format the bot response
const formatBotResponse = (response) => {
  let formattedResponse = escapeHTML(response);

  // Replace #### with <h5> tags (slightly smaller than h4)
  formattedResponse = formattedResponse.replace(/####\s(.*)/g, '<h5>$1</h5>');

  // Replace ### with <h4> tags
  formattedResponse = formattedResponse.replace(/###\s(.*)/g, '<h4>$1</h4>');

  // Replace **{text}** with <strong> tags
  formattedResponse = formattedResponse.replace(
    /\*\*\{(.*?)\}\*\*/g,
    '<strong>$1</strong>',
  );

  // Replace regular ** ** with <strong> tags
  formattedResponse = formattedResponse.replace(
    /\*\*(.*?)\*\*/g,
    '<strong>$1</strong>',
  );

  // Replace [text](link) with <a> tags, but only if the URL is valid
  formattedResponse = formattedResponse.replace(
    /\[(.*?)\]\((.*?)\)/g,
    (match, text, url) =>
      isValidUrl(url)
        ? `<a href="${url}" target="_blank" rel="noopener noreferrer">${text}</a>`
        : match,
  );

  // Replace newlines with <br> tags
  formattedResponse = formattedResponse.replace(/\n/g, '<br>');

  return formattedResponse;
};

// Utility function to format DC label
const formatDcLabel = (key) => `${key} - ${dcMapping[key]}`;

const ProductChatbot = () => {
  // State management
  const [chatOpen, setChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isFirstOpen, setIsFirstOpen] = useState(true);
  const [uuid, setUuid] = useState('');
  const [chatBotLoading, setChatBotLoading] = useState(false);
  const [zipCode, setZipCode] = useState(localStorage.getItem('zip'));
  const [dcCode, setDcCode] = useState(
    localStorage.getItem('dcList')?.split(',')[0] ?? '',
  );
  const [zoneCode, setZoneCode] = useState(
    'Zone ' + localStorage.getItem('zone') ?? '',
  );

  // State for popover
  const [anchorEl, setAnchorEl] = useState(null);

  // State for fullscreen mode
  const [isFullScreen, setIsFullScreen] = useState(false);

  // Refs
  const textFieldRef = useRef(null);
  const userMessageRefs = useRef({});

  // Refs to keep track of previous zip and dc codes
  const prevZipCodeRef = useRef(zipCode);
  const prevDcCodeRef = useRef(dcCode);
  const prevZoneCodeRef = useRef(zoneCode);

  // Initialize chat function
  const initializeChat = useCallback(() => {
    const welcomeMessage = `Welcome! How can I assist you today? Please review your settings before we begin. <br/><br/> Zip Code: ${zipCode}<br/>DC: ${
      formatDcLabel(dcCode) ?? ''
    }<br/>Zone: ${zoneCode}`;
    setMessages([{ text: welcomeMessage, isBot: true }]);
    setUuid(helper.generateUUID()); // Use the improved generateUUID function
    setInputMessage('');
    localStorage.removeItem('chatbot_history');
    setIsFirstOpen(false);
  }, [zipCode, dcCode, zoneCode]);

  // Send message function
  const sendMessage = async (option) => {
    const messageToSend = option || inputMessage?.trim();
    setInputMessage('');

    if (messageToSend) {
      updateMessages(messageToSend, false);
      const payload = buildMessagePayload(messageToSend);
      await sendMessageToAPI(payload);
    }
  };

  // Update messages state
  const updateMessages = (text, isBot) => {
    setMessages((prevMessages) => [...prevMessages, { text, isBot }]);
  };

  // Build message payload
  const buildMessagePayload = (message) => {
    const existingHistory =
      JSON.parse(localStorage.getItem('chatbot_history')) || [];
    const newMessage = {
      inputs: { chat_input: message },
      outputs: { chat_output: null },
    };
    existingHistory.push(newMessage);
    localStorage.setItem('chatbot_history', JSON.stringify(existingHistory));

    return {
      user: helper.getEmplId(),
      zip: zipCode,
      dc: dcCode,
      zone: zoneCode,
      connectionId: uuid,
      message: {
        chat_history: existingHistory,
        chat_input: message,
      },
      output: { chat_output: null },
    };
  };

  // Send message to API
  const sendMessageToAPI = async (payload) => {
    setChatBotLoading(true);
    try {
      const response = await fetch(helper.getAPIHost() + '/SendMessage', {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Source: 'bobboost',
          'X-Api-Key': API_KEY,
        },
      });
      const data = await response.json();
      handleAPIResponse(data);
    } catch (error) {
      handleAPIError(error);
    } finally {
      setChatBotLoading(false);
      focusTextField();
    }
  };

  // Handle API response
  const handleAPIResponse = (data) => {
    if (data.status === 'OK') {
      const botResponse = data.result.output.chat_output;
      const formattedResponse = formatBotResponse(botResponse);
      updateMessages(formattedResponse, true);
      updateLocalStorage(botResponse); // Store the original response
    } else {
      showErrorNotification(data);
    }
  };

  // Handle API error
  const handleAPIError = (error) => {
    window.message.notification(
      'Send Message API: ' + error.message,
      enums.notificationType.E,
    );
  };

  // Update local storage with bot response
  const updateLocalStorage = (botResponse) => {
    const chatHistory =
      JSON.parse(localStorage.getItem('chatbot_history')) || [];
    if (chatHistory.length > 0) {
      const lastMessage = chatHistory[chatHistory.length - 1];
      lastMessage.outputs.chat_output = botResponse;
      chatHistory[chatHistory.length - 1] = lastMessage;
      localStorage.setItem('chatbot_history', JSON.stringify(chatHistory));
    }
  };

  // Show error notification
  const showErrorNotification = (data) => {
    window.message.notification(
      `Failed to send message. Error Code: ${data.errorCode} Error message: ${data.errorMsg}`,
      enums.notificationType.E,
    );
  };

  // Focus text field after sending message
  const focusTextField = () => {
    setTimeout(() => {
      textFieldRef?.current?.focus();
    }, 500);
  };

  // Function to open the popover
  const handleManageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to close the popover
  const handleClosePopover = () => {
    if (!zipCode) {
      window.message.notification(
        'Zip Code is required',
        enums.notificationType.E,
      );
      return;
    }

    // Check if zip or dc code has changed
    if (
      zipCode !== prevZipCodeRef.current ||
      dcCode !== prevDcCodeRef.current ||
      zoneCode !== prevZoneCodeRef.current
    ) {
      // Add bot message indicating the change of DC and Zip Code
      const botMessage = `Settings updated. <br/><br/>New Zip Code: ${zipCode}<br/>New DC: ${formatDcLabel(
        dcCode,
      )}<br/>New Zone: ${zoneCode}`;
      updateMessages(botMessage, true);

      // Update previous values in refs
      prevZipCodeRef.current = zipCode;
      prevDcCodeRef.current = dcCode;
      prevZoneCodeRef.current = zoneCode;
    }

    setAnchorEl(null);
  };

  // Function to toggle fullscreen mode
  const toggleFullScreen = () => {
    setIsFullScreen((prev) => !prev);
  };

  // Initialize chat on first open
  useEffect(() => {
    if (chatOpen && isFirstOpen) {
      initializeChat();
    }
  }, [chatOpen, initializeChat, isFirstOpen]);

  // This effect is responsible for scrolling to the last user message in the chat window
  useEffect(() => {
    if (messages.length > 0) {
      const lastUserMessageIndex = messages.findLastIndex((msg) => !msg.isBot);
      if (lastUserMessageIndex !== -1) {
        userMessageRefs.current[lastUserMessageIndex]?.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }, [messages]);

  // Render components
  return (
    <>
      {/* FAB Button */}
      <Fab
        color="primary"
        aria-label="chat"
        className={`chat-fab ${chatOpen ? 'chat-open' : ''}`}
        onClick={() => setChatOpen(!chatOpen)}
      >
        <FaComments size={25} />
      </Fab>

      {/* ChatBot Window */}
      <Paper
        elevation={3}
        className={`chat-window ${chatOpen ? 'open' : ''} ${
          isFullScreen ? 'full-screen' : ''
        }`}
      >
        {/* Header */}
        <Box className="chat-header">
          <Typography variant="h6">Product Chatbot</Typography>
          <div>
            {/* Expand/Minimize Icon */}
            <IconButton onClick={toggleFullScreen} className="expand-button">
              {isFullScreen ? (
                <FaCompressArrowsAlt title="Minimize" />
              ) : (
                <FaExpandArrowsAlt title="Maximize" />
              )}
            </IconButton>

            {/* Setting Icon */}
            <IconButton onClick={handleManageClick} className="manage-button">
              <FaCog title="Settings" />
            </IconButton>

            {/* Close Icon */}
            <IconButton
              onClick={() => setChatOpen(false)}
              className="close-button"
            >
              <FaTimes title="Close" />
            </IconButton>
          </div>
        </Box>

        {/* Message List */}
        <List className="message-list">
          {messages.map((msg, index) => (
            <ListItem
              key={index}
              className={`message-item ${msg.isBot ? 'bot' : 'user'}`}
              ref={(el) => {
                if (!msg.isBot) {
                  userMessageRefs.current[index] = el;
                }
              }}
            >
              <Avatar className={`avatar ${msg.isBot ? 'bot' : 'user'}`}>
                {msg.isBot ? (
                  <img src="/AI-Bot-Avatar.png" alt="Bot" />
                ) : (
                  localStorage.getItem('ProfileInitial')
                )}
              </Avatar>
              <Paper
                elevation={1}
                className={`message-bubble ${msg.isBot ? 'bot' : 'user'}`}
              >
                {msg.isBot ? ReactHtmlParser(msg.text) : msg.text}
              </Paper>
            </ListItem>
          ))}

          {/* Loading Skeleton */}
          {chatBotLoading && (
            <ListItem className="message-item bot">
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                className="avatar bot skeleton"
              />
              <Skeleton
                variant="rounded"
                className="message-bubble bot"
                height={60}
              />
            </ListItem>
          )}
        </List>

        {/* Product Options or New Session Option */}
        {messages.length > 1 && (
          <Box className="new-session-options">
            <Chip
              key="start-new"
              label="Start new session"
              disabled={chatBotLoading}
              onClick={() => setIsFirstOpen(true)}
              className="product-chip"
            />
          </Box>
        )}

        {/* Input Area */}
        <Box className="input-area">
          <TextField
            fullWidth
            variant="outlined"
            multiline
            minRows={5}
            maxRows={7}
            disabled={chatBotLoading}
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
            autoComplete="off"
            inputRef={textFieldRef}
            InputProps={{
              endAdornment: (
                <IconButton
                  disabled={chatBotLoading}
                  onClick={() => sendMessage()}
                  className="send-button"
                >
                  <FaPaperPlane />
                </IconButton>
              ),
            }}
          />
        </Box>
      </Paper>

      {/* Popover for managing zip code and DC code */}
      <Popover
        id={'manage-chatbot-setting'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className="chatbot-setting"
      >
        <Box padding={2}>
          <Typography variant="h6">Chatbot Settings</Typography>
          {/* Zip Code Setting */}
          <TextField
            label={
              <span>
                Zip Code <span style={{ color: 'red' }}>*</span>
              </span>
            }
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            fullWidth
            margin="normal"
            className="zip-code-setting"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    title="Reset"
                    onClick={() => setZipCode(localStorage.getItem('zip'))}
                  >
                    <GrPowerReset />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* DC Code Setting */}
          <Dropdown
            id="dc-code-dropdown"
            placeholder="DC"
            value={{
              label: formatDcLabel(dcCode), // Using the utility function for formatting
              value: dcCode,
            }}
            options={Object.keys(dcMapping).map((key) => {
              return {
                label: formatDcLabel(key), // Using the utility function for formatting
                value: key,
              };
            })}
            onChange={(selectedOption) => setDcCode(selectedOption?.value)}
          />

          {/* Zone Code Setting */}
          <div style={{ marginTop: '1rem' }}>Pricing Zone</div>
          <Dropdown
            id="dc-code-dropdown"
            placeholder="Pricing Zone"
            value={{
              label: zoneCode,
              value: zoneCode,
            }}
            options={zoneList.map((x) => {
              return {
                label: x,
                value: x,
              };
            })}
            onChange={(selectedOption) => setZoneCode(selectedOption?.value)}
          />

          {/* Update button */}
          <RedButton
            onClick={handleClosePopover}
            label={'Update'}
            outline={true}
            customStyle={'update-setting-button'}
          />
        </Box>
      </Popover>
    </>
  );
};

export default ProductChatbot;
